<template>
  <div>
    <div class="uk-padding-small">
      <vk-breadcrumb class="uk-margin-remove">
        <vk-breadcrumb-item style="cursor: pointer" @click="$router.push({ name: 'workers_list' })">
          Liste des sous-traitants ou prestataires
        </vk-breadcrumb-item>
        <vk-breadcrumb-item :disabled="true">Ajout d'un sous-traitant ou prestataire</vk-breadcrumb-item>
      </vk-breadcrumb>
    </div>
    <div class="uk-padding-small">
      <div class="mb-3">
        <label for="companyName" class="form-label">Nom de la société</label>
        <input type="text" class="form-control" id="companyName" placeholder="nom de la société" v-model="workerPayload.company.name">
      </div>
      <div class="mb-3">
        <label for="registrationNumber" class="form-label">Siren</label>
        <input type="text" class="form-control" id="registrationNumber" placeholder="812444325" v-model="workerPayload.company.registrationNumber">
      </div>
      <SimpleAccordion class="mb-3">
        <template v-slot:accordionTitle>Corps de métiers connus</template>
        <template v-slot:accordionContent>
          <div class="m-3">
            <multiselect
              v-model="workerPayload.buildingTrades"
              :options="buildingTradesList"
              selectedLabel=""
              selectLabel="Cliquez pour sélectionner"
              deselectLabel="Cliquez pour désélectionner"
              track-by="id"
              label="value"
              :multiple="true"
              placeholder="Filtrer les corps de métiers"
            >
              <template slot="noOptions">Aucun résultats</template>
              <template slot="noResult">Aucun élément trouvé</template>
            </multiselect>
          </div>
        </template>
      </SimpleAccordion>
      <hr>
      <div class="mb-3">
        <label for="userEmail" class="form-label">Email utilisateur principal de la société</label>
        <input type="email" class="form-control" id="userEmail" placeholder="email" v-model="workerPayload.user.email">
      </div>
      <div class="mb-3">
        <label for="userLastName" class="form-label">Nom de l'utilisateur</label>
        <input type="text" class="form-control" id="userLastName" placeholder="Nom" v-model="workerPayload.user.lastName">
      </div>
      <div class="mb-3">
        <label for="userFirstName" class="form-label">Prénom de l'utilisateur</label>
        <input type="text" class="form-control" id="userFirstName" placeholder="Prénom" v-model="workerPayload.user.firstName">
      </div>
      <div class="mt-5 text-end">
        <vk-button
          :disabled="recordPending"
          style="border-radius: 5px"
          type="primary"
          @click="addWorker()"
        >
          Ajouter
          <div v-if="recordPending" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
        </vk-button>
      </div>
    </div>
  </div>
</template>

<script>
import backendApi from "@/backend/api";
import store from "@/store";
import { mapActions, mapState } from "vuex";
import SimpleAccordion from "@/components/SimpleAccordion";

export default {
  name: "WorkerAdd",
  components: { SimpleAccordion },
  data: () => ({
    recordPending: false,
    workerPayload: {
      company: {
        registrationNumber: '',
        name: '',
      },
      buildingTrades: [],
      user: {
        email: '',
        firstName: '',
        lastName: '',
      },
      geographicalScope: [],
      dataUrl: {
        siteUrl: window.location.protocol + '//' + window.location.host
      }
    },
    columns: [
      {
        label: 'Corps de métier',
        field: 'value',
      },
    ],
  }),
  computed: {
    ...mapState('workers', { buildingTradesList: 'buildingTradesList' }),
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    addWorker() {
      this.recordPending = true
      backendApi
        .addWorker(store.state.login.user.token, this.workerPayload)
        .then(() => {
          this.addNotification({ message: 'Entreprise ajoutée', status: 'success' })
          this.$router.push({name: 'workers_list'})
        })
        .catch(backendApi.notificationOnError)
        .finally(() => {
          this.recordPending = false
        })
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('workers/updateBuildingTrades')
      .then(() => {
        next()
      })
      .catch(() => {
        next(false)
      })
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>